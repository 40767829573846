<template>
  <div class="container-fluid">
    <div class="row py-3">
      <div class="col-3 col-md-2 col-lg-2 mb-3" v-for="tournament in tournaments" :key="tournament.id">
        <div class="tournament text-center">
          <img :src="tournament.tournament_image" :alt="tournament.name" class="img-fluid">
          <router-link :to="{ name: 'TournamentHome', params: { lang: lang, console: console, tournamentSlug: tournament.slug}}">
            <h2 class="title">{{tournament.name}}</h2>
          </router-link>
          <router-link :to="{ name: 'DivisionHome', params: { lang: lang, console: console, divisionSlug: tournament.division_slug}}">
            {{tournament.division_name}}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      tournaments: []
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'teamFront'
    ])
  },
  mounted () {
    this.$store.dispatch('SET_MENU_PRIMARY_TEAM', 'home')
    this.fetchData()
  },
  methods: {
    fetchData () {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.teamSlug
      const path = `${lang}/console/${console}/team/${slug}/tournaments-where-we-play`
      this.$axios.get(path).then((response) => {
        this.tournaments = response.data.data
      })
    }
  }
}
</script>
